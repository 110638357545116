import { defineStore } from "pinia";
import swal from "sweetalert";
import { datadogLogs } from "@datadog/browser-logs";
import router from "../routes/routes";
import axios from "axios";
import { getActivePinia } from "pinia";
export const useAuthStore = defineStore("auth", {
  state: () => ({
    logoutUrl: null,
    isAuthenticated: false,
    accessToken: null,
    idToken: null,
    employerId: null,
    employerName: null,
    memberId: null,
    verified: false,
    userEmail: null,
    radioButton: "alphabetically",
    customerType: null,
    theme: {
      primary: "#1E1E1D",
      secondary: "#171b1d",
      secondaryTextColor: "#fbfbfb",
      background: "#fbfbfc",
      headerColor: "#fbfbfc",
      footerColor: "#f4f4f4",
      headerTextColor: "#171b1d",
      footerTextColor: "#171b1d",
      primaryActionColor: "#171b1d",
      secondaryActionColor: "#DFFF00",
      textColor: "#DFFF00",
      loadComplete: false,
    },
    mainLogo: null,
    mobileLogo: null,
    groupId: null,
    phoneNumber: "888-220-6650",
    faqTab: 0,
    geoZipcode: null,
    geoAddress: null,
    zipcodeSearchStatus: false,
    geoLocationLoader: false,
    googleWalletLink: null,
    lastLogin: null,
    devices: {
      isAndroid: false,
      isIOS: false,
    },
    currentMedications: [],
    startPageObj: null,
    startPageToken: null,
    loadingStartPage: false,
    doneCheckingOctoberHealth: false,
    hasOctoberHealth: false,
    showDiscountCard: true
  }),

  actions: {
    Logout() {
      this.isAuthenticated = false;
      this.user = {};
      this.userId = null;
      this.userPlan = null;
      this.accessToken = null;
      this.idToken = null;
      this.employerId = null;
      this.memberId = null;
      this.verified = false;
      this.userEmail = null;
      this.customerType = null;
      router.push("/");
      this.resetAllPiniaStores();
      // we could do other stuff like redirecting the user
    },

    resetAllPiniaStores() {
      const activeStores = Object.keys(getActivePinia().state.value);

      activeStores.forEach((store) => {
        getActivePinia()._s.get(store).$reset();
      });
    },

    async Redirect(
      link,
      partnerName,
      productID = "",
      providerID = "",
      memberID = "",
      shouldNotProcessResponse = false
    ) {
      // if (partnerName == "MTM") {
      //   let myHeaders = new Headers();
      //   // myHeaders.append('Access-Control-Allow-Origin', '*');
      //   myHeaders.append("Content-Type", "application/json");
      //   let raw = JSON.stringify({
      //     memberExternalId: this.memberId,
      //     emailId: this.userEmail,
      //   });

      //   let requestOptions = {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${this.idToken}`,
      //     },
      //     body: raw,
      //     redirect: "follow",
      //     mode: "cors",
      //   };
      //   fetch(link, requestOptions)
      //     .then((response) => {
      //       response.json().then((data) => {
      //         if (!!data.data && data.data != "") {
      //           let teleMedicineUrl = JSON.parse(data.data).accessToken;
      //           if (!!teleMedicineUrl && teleMedicineUrl != "") {
      //             let finalRedirectURL =
      //               process.env.VUE_APP_MTM_REDIRECT_URL + teleMedicineUrl;
      //             finalRedirectURL = finalRedirectURL + "?redirect=4";

      //             let popUp = window.open(finalRedirectURL);
      //             try {
      //               popUp.focus();
      //             } catch (e) {
      //               window.location.href = finalRedirectURL;
      //             }
      //           } else {
      //             swal({
      //               // title: "Oops!",
      //               text: "User not found in TeleMedicine.  Please contact support at 888-220-6650 for assistance.",
      //               icon: "error",
      //             });
      //             // alert("User not in TeleMedicine");
      //           }
      //         } else {
      //           swal({
      //             // title: "Oops!",
      //             text: "User not found in TeleMedicine.  Please contact support at 888-220-6650 for assistance.",
      //             icon: "error",
      //           });
      //           // alert("User not in TeleMedicine");
      //         }
      //       });
      //     })
      //     .catch((error) => console.error(error));
      // } else if (partnerName == "Internal") {
      //   try {
      //     router.push(`/dashboard${link}`);
      //   } catch (error) {
      //     console.error("Error::::", error);
      //   }
      // }
      //TODO once all the products start using this start visit call, this if flow should not be used, just the api call below.
      // else if (partnerName == "XP") {
      let myHeaders = new Headers();
      // myHeaders.append('Access-Control-Allow-Origin', '*');
      myHeaders.append("Content-Type", "application/json");

      if (memberID == "") {
        memberID = this.memberId;
      }
      const response = await axios.get(
        process.env.VUE_APP_GET_VISIT_START_LINK,
        {
          params: {
            memberID: memberID,
            productID: productID,
            providerID: providerID && providerID.length > 2 ? providerID : null,
            parentId: this.memberId,
          },

          headers: { Authorization: `Bearer ${this.idToken}`, 'User-Agent-Web': 'memberportalweb' },
        }
      );

      if (shouldNotProcessResponse) {
        return response.data;
      } else {
        if (response.data.success) {
          if (
            response.data.launchURL &&
            (response.data.launchURL.includes("myrevive.health") || response.data.launchURL.includes("dashboard"))
          ) {
            // if(response.data.launchURL.includes("medication")){
            //   router.push({ name: 'Medication HomePage' });
            // }else if(response.data.launchURL.includes("mental-health")){
            //   router.push({ name: 'My Mental Health' });
            // }else{
            //   window.location.href = response.data.launchURL;
            // }
            // window.history.pushState(null, document.title, response.data.launchURL);

            window.location.href = response.data.launchURL;
            return response.data;
          } else if (response.data.failureReason == 2) {
            return response.data;
          } else if (response.data.failureReason == 3) {
            return response.data;
          } else {
            let popUp = window.open(response.data.launchURL);
            try {
              popUp.focus();
            } catch (e) {
              window.location.href = response.data.launchURL;
            }
            return response.data;
          }
        } else {
          if (response.data.failureReason == 1) {
            return response.data;
          }
          if (response.data.failureReason == 2) {
            return response.data;
          } else {
            swal({
              text: response.data.message,
              icon: "error",
            });
            return response.data;
          }
        }
      }

      // } else {
      //   window.open(link);
      // }
    },
    async fetchLocationData(){
      return new Promise((resolve, reject) => {
      const apiUrl = 'https://ipapi.co/json/';
      axios.get(apiUrl).then((response) => {
        this.zipcodeSearchStatus = true;
        this.geoZipcode = response.data.postal;
        this.geoAddress = response.data.city + ", " + response.data.region;
        this.geoLocationLoader = true;
        resolve();
      }).catch((err) => {
        this.zipcodeSearchStatus = false;
        this.geoLocationLoader = false;
        swal({
          text: "You currently have the geo location function turned off for this site.",
          icon: "error",
        });
        reject(err.response.data);
      })
    });
    },
    async GeolocationCoordinates() {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          swal({
            text: "You currently have the geo location function turned off for this site.",
            icon: "error",
          });
          reject(
            new Error(
              "You currently have the geo location function turned off for this site."
            )
          ); // Reject if geolocation not supported or disabled
          this.geoLocationLoader = false;
          return;
        }

        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            const apiKey = process.env.VUE_APP_GOOGLE_GEOLOCATION_KEY;
            this.geoLocationLoader = true;

            axios
              .get(
                `${process.env.VUE_APP_GOOGLE_GEOLOCATION_API}?latlng=${latitude},${longitude}&key=${apiKey}`
              )
              .then((response) => {
                if (response.data.status == "ZERO_RESULTS") {
                  this.zipcodeSearchStatus = false;
                  this.geoLocationLoader = false;
                  resolve(); // Resolve with no result
                  return;
                }
                const addressComponents =
                  response.data.results[0].address_components;
                let zipcode;
                const postalCode = addressComponents.find((component) =>
                  component.types.includes("postal_code")
                );
                if(postalCode)
                  zipcode = postalCode.long_name;

                let state;
                const administrativeAreaLevel1 =  addressComponents.find((component) =>
                  component.types.includes("administrative_area_level_1")
                );
                if(administrativeAreaLevel1)
                  state = administrativeAreaLevel1.short_name;

                let city;
                const locality = addressComponents.find((component) =>
                  component.types.includes("locality")
                );
                if(locality)
                  city = locality.long_name

                const address = city + ", " + state;

                this.zipcodeSearchStatus = true;
                this.geoZipcode = zipcode;
                this.geoAddress = address;
                this.geoLocationLoader = false;
                resolve();
              });
          },
          (error) => {
            console.log("Geolocation error", error);
            swal({
              text: "You currently have the geo location function turned off for this site.",
              icon: "error",
            });
            this.geoLocationLoader = false;
            reject(error); // Reject on geolocation error
          }
        );
      });
    },

    async getAddressFromZipcode(zipcode) {
      const apiKey = process.env.VUE_APP_GOOGLE_GEOLOCATION_KEY;

      axios
        .get(
          `${process.env.VUE_APP_GOOGLE_GEOLOCATION_API}?address=${zipcode}&key=${apiKey}`
        )
        .then(
          (response) => {
            if (response.data.status == "OK") {
              const addressComponents =
                response.data.results[0].address_components;

              const state = addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
              ).short_name;

              const city = addressComponents.find((component) =>
                component.types.includes("locality")
              ).long_name;

              const address = city + ", " + state;
              this.zipcodeSearchStatus = true;
              this.geoAddress = address;
            } else {
              this.zipcodeSearchStatus = false;
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
    },

    async fetchEmployerByEmployerID(employerID, idToken) {
      await axios
        .get(process.env.VUE_APP_GET_EMPLOYERID, {
          params: {
            employerID: employerID,
          },
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((response) => {
          datadogLogs.logger.info(
            `Called GetEmployerByEmployerID for employerID=${employerID} Response: ${response}`
          );
          this.groupId = response.data.groupID;
          this.customerType = response.data.customerType;
          this.employerName = response.data.name;
        })
        .catch((err) => {
          datadogLogs.logger.error(
            `Called GetEmployerByEmployerID for employerID==${employerID} Response: ${err}`
          );
          this.groupId = null;
        });
    },

    async fetchGoogleWalletPass(idToken, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_GET_GOOGLE_WALLET, data, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            this.googleWalletLink = response.data.data;
            datadogLogs.logger.info(
              `Called for fetchGoogleWalletPass for Google wallet pass Response : ${response}`
            );
            resolve(response);
          })
          .catch((err) => {
            this.googleWalletLink = null;
            datadogLogs.logger.error(
              `Called for fetchGoogleWalletPass for Google wallet pass Response : ${err}`
            );
            reject(err);
          });
      });
    },
    async fetchAppleWalletPass(idToken, data, memberID) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_GET_APPLE_WALLET, data, {
            params: { memberID },
            headers: { Authorization: `Bearer ${idToken}` },
            responseType: 'blob'
          }).then((response) => {
              datadogLogs.logger.info(
                `Called for fetchAppleWalletPass for Apple wallet pass Response : ${response}`
              );
              resolve(response);
          }).catch((err) => {
            datadogLogs.logger.error(
              `Called for fetchAppleWalletPass for Apple wallet pass Response : ${err}`
            );
            reject(err);
        });
      });
    },
    async fetchProvidersByState(memberID, state, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_PROVIDER_LIST_BY_STATE, {
            params: { state, memberID },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called GetProvidersByState for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called GetProvidersByState for memberID==${memberID} Response: ${err}`
            );
            reject(err.response);
          });
      });
    },

    async fetchProviderAvailability(memberID, providerID, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_AVAILABILITY, {
            params: { memberID, providerID },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called GetProviderAvailability for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called GetProviderAvailability for memberID==${memberID} Response: ${err}`
            );
            reject(err.data);
          });
      });
    },

    async UpdateProviderIDForMember(memberID, providerID, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_UPDATE_PROVIDER_ID, null, {
            params: { memberID, providerID },
            headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called UpdateProviderID for memberID=${memberID} and providerID=${providerID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called UpdateProviderID for memberID=${memberID} and providerID=${providerID} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },

    async fetchAppointmentsByMemberID(memberID, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_APPOINTMENTS, {
            params: { memberID },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called GetAppointmentsByMemberID for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called GetAppointmentsByMemberID for memberID==${memberID} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },

    async fetchVisitLimitCheck(memberID, productID, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_VISIT_LIMIT_CHECKER, {
            params: { memberID, productID },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called HasExceededVisitLimit for memberID=${memberID} and ProductID=${productID}. Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called HasExceededVisitLimit for memberID==${memberID} and ProductID=${productID}. Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },

    async fetchSwiftPharmacyByMemberID(memberID, vendorID, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_SWIFT_PHARMACY, {
            params: { memberID, vendorID },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called GetSwiftPharmacyByMemberID for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called GetSwiftPharmacyByMemberID for memberID==${memberID} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },

    async cancelAppointment(memberID, cancelAppointmentPayload, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_CANCEL_APPOINTMENT,
            cancelAppointmentPayload,
            {
              params: { memberID },
              headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then((response) => {
            datadogLogs.logger.info(
              `Called CancelMemberAppointment for memberID=${memberID} and cancelAppointmentPayload=${cancelAppointmentPayload} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called CancelMemberAppointment for memberID=${memberID} and cancelAppointmentPayload=${cancelAppointmentPayload} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },

    async UploadAppointmentPhoto(
      memberID,
      appointmentID,
      uploadPhotoPayload,
      idToken
    ) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_UPLOAD_APPOINTMENT_PHOTO,
            uploadPhotoPayload,
            {
              params: { memberID, appointmentID },
              headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then((response) => {
            datadogLogs.logger.info(
              `Called UploadAppointmentPhoto for memberID=${memberID} and uploadPhotoPayload=${uploadPhotoPayload} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called UploadAppointmentPhoto for memberID=${memberID} and uploadPhotoPayload=${uploadPhotoPayload} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },

    async scheduleUrgentVisit(memberID, ucAppointmentPayload, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_SCHEDULE_URGENT_VISIT,
            ucAppointmentPayload,
            {
              params: { memberID },
              headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then((response) => {
            datadogLogs.logger.info(
              `Called StartUrgentVisit for memberID=${memberID} and ucAppointmentPayload=${ucAppointmentPayload} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called StartUrgentVisit for memberID=${memberID} and ucAppointmentPayload=${ucAppointmentPayload} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },

    async scheduleVPCVisit(memberID, vPCAppointmentPayload, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SCHEDULE_VPC_VISIT, vPCAppointmentPayload, {
            params: { memberID },
            headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called StartVPCVisit for memberID=${memberID} and vPCAppointmentPayload=${vPCAppointmentPayload} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called StartVPCVisit for memberID=${memberID} and vPCAppointmentPayload=${vPCAppointmentPayload} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },

    // FETCH PHONE NUMBER
    async fetchGetThemeByEmployerID(employerID, idToken) {
      await axios
        .get(process.env.VUE_APP_GET_THEME_BY_EMPLOYERID, {
          params: {
            employerID: employerID,
          },
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((response) => {
          datadogLogs.logger.info(
            `Called GetThemeEmployerID for employerID=${employerID} Response : ${response}`
          );

          if (
            response.data &&
            response.data.data &&
            response.data.data.phoneNumber !== null
          ) {
            this.phoneNumber = response.data.data.phoneNumber;
          }
        })
        .catch((err) => {
          datadogLogs.logger.error(
            `Called GetThemeEmployerID for employerID==${employerID} Response : ${err}`
          );
        });
    },

    // Get theme by email
    async fetchGetThemeByEmail(email, employerID, isForSelfSignUp = null) {
      if (employerID == null) employerID  = ""
      if (email == null) email = ""
      await axios
        .get(process.env.VUE_APP_GET_THEME_BY_EMAIL, {
          params: {
            email: email,
            employerId: employerID
          },
        })
        .then((response) => {
          datadogLogs.logger.info(
            `Called fetchGetThemeByEmail for email=${email} Response : ${response}`
          );
          
          if (response.data && response.data.data && response.data.data.enableCustomization) {
            this.mainLogo = response.data.data.mainLogo;
            this.theme.primary = response.data.data?.primaryColor?.hex;
            this.theme.secondary = response.data.data?.secondaryColor?.hex;
            this.theme.background = response.data.data?.backgroundColor?.hex;

            try {
              this.phoneNumber = response.data.data?.phoneNumber?.length > 1 ? response.data.data?.phoneNumber.split('+1 ')[1]?.replace(/ /g, "-") : "888-220-6650";
            } catch {
              this.phoneNumber = "888-220-6650";
            }

            this.theme.headerColor = response.data.data?.headerColor?.hex;
            this.theme.footerColor = response.data.data?.footerColor?.hex;
            this.theme.primaryActionColor = response.data.data?.primaryActionColor?.hex;
            this.theme.secondaryActionColor = response.data.data?.secondaryActionColor?.hex;

            if(response.data.data.brandingType && response.data.data.brandingType === 'revive'){
              this.theme.primaryActionColor = '#171b1d';
              this.theme.textColor = '#DFFF00';
            } else{
              if(!this.theme.primaryActionColor) this.theme.primaryActionColor = '#171b1d';
              this.theme.textColor = '#FFF';
            }
            if(isForSelfSignUp){
              this.theme.loadComplete = false;
              return;
            }
          } else{
           throw "enableCustomization is false"; 
          }
          this.theme.loadComplete = true;
        })
        .catch((err) => {
          this.theme.primary =  "#dfff00",
          this.theme.secondary = "#171b1d",
          this.theme.background = "#fbfbfb",
          this.theme.headerColor = "#fbfbfc",
          this.theme.footerColor = "#f4f4f4",
          this.theme.primaryActionColor = "#171b1d",
          this.theme.secondaryActionColor = "#FFC600",
          this.theme.textColor = "#DFFF00";

          this.mainLogo = null;

          this.phoneNumber = "888-220-6650";
          this.theme.loadComplete = true;
          datadogLogs.logger.error(
            `Called fetchGetThemeByEmail for email==${email} Response : ${err}`
          );
        });
    },
    //TODO: add error catching from merge and resolve conflict.
    async fetchGetMemberMedications(memberID) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_MEMBER_MEDICATIONS, {
            params: { memberID },
            headers: { Authorization: `Bearer ${this.idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called fetchGetMemberMedications for memberID=${memberID} Response: ${response}`
            );
            this.currentMedications = [];
            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.length > 0
            ) {
              this.currentMedications = [...response.data.data];
            }
            resolve(response.data);
          })
          .catch((err) => {
            this.currentMedications = [];
            datadogLogs.logger.error(
              `Called fetchGetMemberMedications for memberID==${memberID} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },
    async postMedicationRefill(medicationRefill, memberID) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_POST_MEDICATION_REFILL, medicationRefill, {
            headers: { Authorization: `Bearer ${this.idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called postMedicationRefill for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called postMedicationRefill for memberID==${memberID} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },
    async postMedicationTransfer(medicationRefill, memberID) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_POST_MEDICATION_REFILL, medicationRefill, {
            headers: { Authorization: `Bearer ${this.idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called postMedicationRefill for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called postMedicationRefill for memberID==${memberID} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },
    async fetchGetMemberMessages(employerID) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_MEMBER_MESSAGES_URL, {
            params: { employerID },
            headers: { Authorization: `Bearer ${this.idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called fetchGetMemberMessages Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called fetchGetMemberMessages Response: ${err}`
            );
            reject(err.response.data);
            console.log(err);
          });
      });
    },
    async findPharmacies(findPharmacyRequest) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_POST_FIND_PHARMACIES_URL,
            findPharmacyRequest,
            {
              headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
                Authorization: `Bearer ${this.idToken}`,
              },
            }
          )
          .then((response) => {
            datadogLogs.logger.info(
              `Called findPharmacies for findPharmacyRequest=${findPharmacyRequest} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called findPharmacies for findPharmacyRequest=${findPharmacyRequest} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },

    async fetchLookupListByType(type, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_LOOKUP_LIST_BY_TYPE, {
            params: { type },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called GetLookups by type=${type} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called GetLookups by type=${type} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },

    async saveSwiftPharmacy(swiftPharmacyPayload, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_SAVE_SWIFT_PHARMACY,
            swiftPharmacyPayload,
            {
              headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then((response) => {
            datadogLogs.logger.info(
              `Called saveSwiftPharmacy for memberID=${swiftPharmacyPayload.memberID} and swiftPharmacyPayload=${swiftPharmacyPayload} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called saveSwiftPharmacy for memberID=${swiftPharmacyPayload.memberID} and swiftPharmacyPayload=${swiftPharmacyPayload} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },
    async fetchMentalHealthProviders(memberID, state, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_MH_PROVIDERS, {
            params: { state, memberID },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called fetchMentalHealthProviders for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called fetchMentalHealthProviders for memberID==${memberID} Response: ${err}`
            );
            reject(err.response);
          });
      });
    },
    async getCareCoordinators(memberID, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_MH_CARECOORDINATOR, {
            params: { memberID },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called GetCareCoordinators for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called GetCareCoordinators for memberID==${memberID} Response: ${err}`
            );
            reject(err.response);
          });
      });
    },
    async fetchMentalHealthProblems(memberID, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_MH_PROBLEMS, {
            params: { memberID },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called fetchMentalHealthProblems for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called fetchMentalHealthProblems for memberID==${memberID} Response: ${err}`
            );
            reject(err.response);
          });
      });
    },
    async scheduleCase(memberID, mentalHealthPayload, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SUBMIT_MH_CASE, mentalHealthPayload, {
            params: { memberID },
            headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called scheduleCase for memberID=${memberID} and mentalHealthPayload=${mentalHealthPayload} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called scheduleCase for memberID=${memberID} and mentalHealthPayload=${mentalHealthPayload} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    },
    async RedirectToStartPageReturnUrl() {
      if (this.startPageObj && this.startPageObj.returnURL) {
        window.location.replace(this.startPageObj.returnURL);
        this.startPageObj = null;
      }
    },
    async fetchMemberCase(memberID, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_GET_MEMBER_CASE, {
            params: { memberID },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called GetMemberCase for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called GetMemberCase for memberID==${memberID} Response: ${err}`
            );
            reject(err.response);
          });
      });
    },
    async fetchMemberSubscription(memberID, idToken) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ENABLE_GET_SUBSCRIPTION, {
            params: { memberID },
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((response) => {
            datadogLogs.logger.info(
              `Called GetMemberSubscription for memberID=${memberID} Response: ${response}`
            );
            resolve(response.data.data);
          })
          .catch((err) => {
            datadogLogs.logger.error(
              `Called GetMemberSubscription for memberID==${memberID} Response: ${err}`
            );
            reject(err.response.data);
          });
      });
    }
  },
  persist: true
});
