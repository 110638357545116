import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// change theme colors
const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primaryColor: "#1E1E1D",
        secondaryColor: "#171b1d",
        backgroundColor: "#fbfbfc",
        warning: "#ffc600",
        hoverColor: "#DCE3EB",
        greyColor: "#efefef",
        darkGrey: "#888888",
        lightPopupColor: "#E7EEEA",
        headerColor: "#fbfbfc",
        footerColor: "#f4f4f4",
        primaryActionColor: "#171b1d",
        secondaryActionColor: "#DFFF00",
        headerTextColor: "#171b1d",
        footerTextColor: "#171b1d",
        secondaryTextColor: "#fbfbfb",
        yellowColor: "#DFFF00"
      },
    },
  },
});

export default vuetify;
