import vuetify from '@/plugins/vuetify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueCompositionAPI from '@vue/composition-api';
import axios from 'axios';
import Maska from 'maska';
import mixpanel from 'mixpanel-browser';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import VPhoneNumber from 'v-phone-number';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import vueCountryRegionSelect from 'vue-country-region-select';
import { VSelect, VTextField } from 'vuetify/lib';
import App from './App.vue';
import './assets/styles/index.css';
import { Auth0Plugin } from './auth';
import router from './routes/routes';

library.add(fas);
mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  persistence: "localStorage",
});

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-text-field', VTextField);
Vue.component('v-select', VSelect);
Vue.component('v-phone-number', VPhoneNumber);

// FIXME: shouldn't be hardcoded
const getCurrentDomain = () => {
  if (
    window.location.href.indexOf('prodigyrxlogi') > -1 ||
    window.location.href.indexOf('pattycarehealt') > -1 ||
    window.location.href.indexOf('epiquehealt') > -1 ||
    window.location.href.indexOf('membertes') > -1 ||
    window.location.href.indexOf('mylivedo') > -1 ||
    window.location.href.indexOf('gotobenefi') > -1
  ) {
    return 'login.yourbenefitportal.com';
  }
  return process.env.VUE_APP_AUTH0_CONFIG_DOMAIN;
};
const domain = getCurrentDomain();
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
Vue.use(Maska)
  .use(PiniaVuePlugin)
  .use(VueCompositionAPI)
  .use(vueCountryRegionSelect)
  .use(VueAxios, axios)
  .use(Auth0Plugin, {
    domain,
    clientId: process.env.VUE_APP_AUTH0_CONFIG_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_CONFIG_AUDIENCE,
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname,
      );
    },
  });
Vue.config.productionTip = false;
new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
