import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard, homeAuthGuard } from "@/auth/authGuard";

const HomeView = () => import("../views/HomeView.vue");
const DashBoard = () => import("../views/DashBoard.vue");
const Medication = () => import("../views/medication/Medication.vue");
const MentalHealth = () => import("../views/mental-health/MentalHealth.vue");
// const MentalRecord = () => import("../views/mental-record/MentalRecord.vue");
const Health = () => import("../views/health/Health.vue");
const HelpDesk = () => import("../views/helpdesk/HelpDesk.vue");
const Registration = () => import("../views/Registration.vue");
const RegistrationSelfSignup = () =>
  import("../views/RegistrationSelfSignup.vue");
const RegistrationSelfSignupComplete = () =>
  import("../views/RegistrationSelfSignupComplete.vue");
const HomePage = () => import("../views/homepage/HomePage.vue");
const MedicationList = () => import(".././components/Medication/MedicationList.vue");
const ScheduleVisit = () =>import("../components/Medication/ScheduleVisit.vue");
const FAQ = () => import("../views/Faq.vue");
 const MedicationHomepage = () => import("../components/Medication/MedicationHomepage.vue");
const MedicationTheory = () =>  import("../components/Medication/MedicationByTherapy.vue");
const MemberSearchEnrollment = () =>
  import("../views/MemberSearchEnrollment.vue");

const RegistrationD2C = () => import("../views/RegistrationD2C.vue");
const Profile = () => import("../views/Profile.vue");
const UCVisit = () => import("../components/Visit/UC-Visit/Index.vue");
const MedicalHistory = () =>  import("../views/medical-history/Index.vue");
const SSOLogin = () => import("../views/SSOLogin.vue");
const Start = () => import("../views/Start.vue");

const HomePageV2 = () => import("../views/homepage/HomePageV2.vue");
const DownloadRevive = () => import("../views/DownloadRevive.vue");

const Logout = () => import("../views/Logout.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/ssologin",
    name: "home",
    component: SSOLogin,
  },
  {
    path: "/logout",
    name: "home",
    component: Logout,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: homeAuthGuard,
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/registrationDTC",
    name: "RegistrationDTC",
    component: RegistrationD2C,
  },
  {
    path: "/selfsignup",
    name: "RegistrationSelfSignup",
    component: RegistrationSelfSignup,
  },
  {
    path: "/completeselfsignup",
    name: "RegistrationSelfSignupComplete",
    component: RegistrationSelfSignupComplete,
  },
  {
    path: "/registration-search",
    name: "Registration Search",
    component: MemberSearchEnrollment,
  },
  {
    path: "/start",
    name: "start",
    component: Start,
  },
  {
    path: "/download-revive-app",
    name: "Download Revive App",
    component: DownloadRevive,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashBoard,
    beforeEnter: authGuard,
    children: [
      {
        path: "/dashboard",
        name: "HomePage",
        component: HomePage,
      },
      {
        path: "/v2-dashboard",
        name: "HomePageV2",
        component: HomePageV2
      },
      {
        path: "/uc-visit",
        name: "Urgent Call Visit",
        component: UCVisit,
      },
      {
        path: "/dashboard/medication",
        name: "My Medication",
        component: Medication,
        children: [
          {
            path: "/dashboard/medication",
            name: "Medication HomePage",
            component: MedicationHomepage,
          },
          {
            path: "/dashboard/medication/medication-quick-reference",
            name: "Medication List",
            component: MedicationList,
          },
          {
            path: "/dashboard/medication/schedule-visit",
            name: "Schedule Visit",
            component: ScheduleVisit,
          },
          {
            path: "/dashboard/medication/medication-by-therapy",
            name: "Medication By Therapy",
            component: MedicationTheory,
          },
        ],
      },
      {
        path: "/dashboard/medical-history",
        name: "Medical History",
        component: MedicalHistory,
      },
      {
        path: "/dashboard/medication/faq",
        name: "FAQ",
        component: FAQ,
      },

      {
        path: "/dashboard/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/dashboard/health",
        name: "My Health",
        component: Health,
      },
      {
        path: "/dashboard/mental-health",
        name: "My Mental Health",
        component: MentalHealth,
      },
      // {
      //   path: "/dashboard/mental-record",
      //   name: "My Mental Record",
      //   component: MentalRecord,
      // },
      {
        path: "/dashboard/helpdesk",
        name: "HelpDesk",
        component: HelpDesk,
      },

      // FIXME: this 404 wildcard is under the dashboard, so any routes that follow
      //   will be ignored and picked up by the dashboard redirct
      {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: HomePage,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
