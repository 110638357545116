export const lookupTypes = {
  memberType: {
    externalID: "233c12d8-e769-43ca-a06d-4f8e96afb811",
    primary: "3eec12d8-e769-43ca-a06d-4f8e96afb811",
    spouse: "3eec12d8-e769-43ca-a06d-4f8e96afb812",
  },
  dependentType: {
    adultChild: "3eec12d8-e769-43ca-a06d-4f8e96afb813",
    child: "3eec12d8-e769-43ca-a06d-4f8e96afb814",
    spouse: "3eec12d8-e769-43ca-a06d-4f8e96afb812",
  },
  gender: {
    male: "3eec12d8-e769-43ca-a06d-4f8e96afb815",
    female: "3eec12d8-e769-43ca-a06d-4f8e96afb816",
    default: "3eec12d8-e769-43ca-a06d-4f8e96afb817",
  },
  menuLocations: {
    footer: "419C12D8-D769-43CD-A06D-5F9A99DFA813",
    leftSideBar: "419C12D8-D769-43CD-A06D-5F9A99DFA814",
    all: "419C12D8-D769-43CD-A06D-5F9A99DFA815",
  },
  productAttributes: {
    buttonText: "9063bd58-3130-4d53-b451-795e056e2d58",
    faq: "128312d8-e769-44ca-a06d-4f8e96afb813",
    topPane: "9063bd58-3130-4d53-b451-795e056e2d50",
    leftPane: "9063bd58-3130-4d53-b451-795e056e2d51",
    rightPane: "9063bd58-3130-4d53-b451-795e056e2d52",
    bottomPane: "9063bd58-3130-4d53-b451-795e056e2d53",
    memberSelect: "411c12d8-d769-43cd-a45b-5f8a96dfb911",
    directSchedule: "64822d9b-e840-4c5d-8717-33a45fff9215",
    contractType: "9fbac26b-c614-42c3-afa8-41e93fb936a6",
    dontShowDrugCard: "482d7d7c-ce23-4102-abe3-be4ba369eb1e"
  },
  BHSContractTypes: {
    AssistanceProgramMarkedForDeletion: "0cb8825a-b353-458a-92c8-532a39a230e4",
    AssistanceProgram: "b85f9ebf-caed-4dcf-84af-24a8262d6833",
    CareConnect: "84701210-2ceb-4226-8cf7-f383f8bde5c9",
    FeeForService: "c5582bae-1cab-4f09-ae63-d01d551b6313",
    GuideAndThrive: "0e38bdc4-003c-4105-ad89-fccb3d8a2452",
    MentalHealthRX: "afe418fb-62ab-42e1-af7c-483708111c0b",
    Voluntary: "2c5d3258-15cc-4a20-9c46-ea4ecf09335f",
    Wellness: "5259a3f3-a58e-4b07-93ca-5cfbb9e178cc"
  },
  schedulingOptions: {
    none: "13a63635-5b39-4faa-8b7c-9ecbaadd822e",
    thrive: "206fa1a1-ba1c-4f66-8b92-0570914498f6",
    guideAndThrive: "e22c0feb-86db-49bc-9a9d-80f473f9180c",
  },
  DONTSHOWDRUGCARDOPTIONS: {
    YES: "4C36E6C1-61EC-42C7-B41A-4D0FA3C18DDE",
    NO: "6802221D-D96A-4446-8D4A-6E6EE588BCF0"
  },
  productTypes: {
    mentalHealth: "aa3312d8-e769-44ca-a06d-4f8e96afb812",
    healthCare: "aa3312d8-e769-44ca-a06d-4f8e96afb813",
    VPC: "95A47808-B5E7-4D14-B56B-518F87BD4B67",
    VUC: "128312D8-E769-44CA-A06D-4F8E96AFB811",
    PRESCRIPTIONS: "39907A0A-FE87-412E-A731-35B426841227",
    PSYCHIATRY: "22B8C300-AC57-4421-BF3E-3699A1E103A4",
    DERMATOLOGY: "D0799830-3C8E-4415-90CB-A3D68223556F",
    ENDOCRINOLOGY: "EC427C1F-D501-43A1-8509-63728B4681B8",
    MENTALHEALTHVISIT: "E76F4AE7-6D97-4F9B-B3F5-04079C538E7B",
    
    PHARMACY: "D6CD69A1-1D08-4026-9EB2-AEA7B6C74694",
    GNCVUC: "39907A0A-FE87-412E-A731-35B426841229",
    GNCVPC: "0820FC1F-2317-418B-B315-C910049FC06F"
  },
  PRODUCTCATEGORIES: {
    RXPRODUCTS: "AA3312D8-E769-44CA-A06D-4F8E96AFB810"
  },
  swiftTimeZones: [
      "Eastern Standard Time",
      "Central Standard Time",
      "Mountain Standard Time",
      "Pacific Standard Time",
      "Alaskan Standard Time",
      "Hawaiian Standard Time",
  ],
  gncEmployerIds: [
    '7B0793C8-F305-4AE8-81F2-C7CCB2A55A04'
  ],
  vendors: {
    swift: "9c702c19-d8a5-4df7-b6a3-fbc3087f9a09",
    mediOrbis: "91bffcf6-4dff-4380-9962-d8374ec2a22b",
    bhs: "a485551f-72a3-461d-8332-e9e05d38f960"
  },
  demoEmployerID: "CE943A4D-1F43-450A-A3B2-313411EF8F6E",
  mediOrbisTestImgUrl: 'https://test3.mediorbis.com/msmd-patient/provImg.htm',
  mediOrbisProviderType: {
    physician: 1,
    healthCoach: 2
  },
  doneNothingSurveyGuid: "271701aa-d016-478b-a6ad-12b3a0546db5",
  allowedCategoryIDs: [
    "aa3312d8-e769-44ca-a06d-4f8e96afb812",
    "00000000-0000-0000-0000-000000000000"
  ]
};
//TODO: Move this
export const countries = ["US", "CA", "PR", "PE", "JM", "ES", "GT", "VI" ];