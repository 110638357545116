<template>
  <div></div>
</template>
<script>
import { useAuthStore } from "@/store/auth";

export default {
  name: "AutoLogout",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  data() {
    return {
      events: ["click", "mousemove", "scroll", "keypress", "load"],
      logoutTimer: null,
      clearTimer: null,
    };
  },
  methods: {
    setTimers() {
      this.clearTimer = setTimeout(() => {
        this.$auth.logout({
          returnTo: this.authStore.logoutUrl,
        });
      }, 20 * 60 * 1000);
      
    },
    resetTimer() {
      clearTimeout(this.clearTimer);

      this.setTimers();
    },
  },
  mounted() {
    this.events.forEach((e) => {
      window.addEventListener(e, this.resetTimer);
    }, this);
  },
};
</script>
