import { lookupTypes } from "@/commons/lookupTypes";
import { fetchProfileMemberDetails } from "@/services/homeProduct";
import axios from "axios";
import { defineStore } from "pinia";

export const useRegisterStore = defineStore("state", {
  state: () => ({
    radio: "alpha",
    benefitsTitle: "",
    authToken: null,
    registrationCode: null,
    geoZipcode: null,
    geoAddress: null,
    userDetails: {
      member: {
        dependentAddOrEdit: 2,
        memberID: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phonePrimary: "",
        phoneSecondary: "",
        dateOfBirth: "",
        gender: "",
        preferlanguage: "",
        timeZone: "",
        primaryStreet: "",
        primaryStreetTwo: "",
        primaryCity: "",
        primaryRegion: "",
        primaryPostal: "",
        memberType: "3eec12d8-e769-43ca-a06d-4f8e96afb811",
        product: "",
        employerID: "",
        memberUniqueID: "",
        parentID: "",
        memberProviders: [
          {
            memberProvidersRowID: "",
            memberID: "",
            providerID: "",
            entityID: "",
          },
        ],
        memberEmergencyContact: {
          name: "",
          phone: "",
          relationship: ""
        },
        extraFields: []
      },
      dependents: [
        {
          memberID: "",
          parentID: "",
          dependentAddOrEdit: 0,
          dependentPhoneObject: false,
          isPrimarySameAddress: false,
          memberType: "",
          memberTypeName: "",
          firstName: "",
          middleName: "",
          lastName: "",
          email: "",
          phonePrimary: "",
          dateOfBirth: "",
          gender: "",
          primaryStreet: "",
          primaryStreetTwo: "",
          primaryCity: "",
          primaryRegion: "",
          primaryPostal: "",
          preferlanguage: "",
          timeZone: "",
          product: "",
          memberUniqueID: "",
          memberProviders: [
            {
              memberProvidersRowID: "",
              memberID: "",
              providerID: "",
              entityID: "",
            },
          ],
          memberEmergencyContact: {
            name: "",
            phone: "",
            relationship: ""
          },
          extraFields: []
        },
      ],
      dependentExtraFields: [],
      applyAddressFor: "",
      tempAddress: {
        primaryStreet: "",
        primaryStreetTwo: "",
        primaryCity: "",
        primaryRegion: "",
        primaryPostal: "",
      },
    },
    employerProfileAttribute: {
      profileEdit: false,
      displayDependent: false,
      dependentAddOrEdit: 0,
    },
    employerSystemAttribute: {
      allowMinorsAsPrimary: false,
      minimumPrimaryAge: 18,
    },
    signupPageProductDetails: null,
    planURL: null,
    employerIDURL: null,
    preferlanguage: null,
    timeZone: null,
    password: null,
    cpassword: null,
    productCard: null,
    mentalProductCard: null,
    mtmUser: null,
    referenceId: null,
    subscriptionURL: null,
    addPaymentMemberId: null,
    familyValidationBoolean: false,
    memberSelected: null,
    checkboxClick: null,
    productName: null,
    familyData: [],
    familyDataLoading: false,
    medicationItems: null,
    dropDownlist: null,
    brokerId: null,
    memberUniqueID: "",
    healthGorilaID: "",
    memberForVisit: "",
    providerForVisit: null,
    formularyUser: null,
  }),
  getters: {
    //determines if the member has cause to show a member drop down, common for starting visits.
    //returns an empty array or an array of members depending on the requirements
    getFamilyMembers: (state) => {
      return (memberID) => {
        //check to see if there are any dependents, if not we can shortcut.
        if (
          state.userDetails.dependents &&
          state.userDetails.dependents.length > 0
        ) {
          //check to find who the current member is.
          let currentMember;
          if (state.userDetails.member.memberID == memberID) {
            currentMember = state.userDetails.member;
          } else {
            currentMember = state.userDetails.dependents.find(
              (x) => x.memberID == memberID
            );
          }
          //spouse and primary can see themselves plus any children.

          if (
            currentMember.memberType == lookupTypes.dependentType.spouse ||
            currentMember.memberType == lookupTypes.memberType.primary
          ) {
            let returnArray = [];

            returnArray.push({
              name: currentMember.firstName + " " + currentMember.lastName,
              memberID: memberID,
              state: currentMember.primaryRegion,
              providerID: currentMember.memberProviders[0]?.providerID,
              memberUniqueID: currentMember.memberUniqueID,
              email: currentMember.email,
              phonePrimary: currentMember.phonePrimary,
              memberType: currentMember.memberType,
              timeZone: currentMember.timeZone,
              parentID: currentMember.parentID,
              memberEmergencyContact: currentMember.memberEmergencyContact,
              dateOfBirth: currentMember.dateOfBirth
            });

            //now get the children if there are any.
            let filteredDep = state.userDetails.dependents.filter(
              (x) => x.memberType == lookupTypes.dependentType.child
            );
            if (filteredDep && filteredDep.length) {
              filteredDep.forEach((x) => {
                returnArray.push({
                  name: x.firstName + " " + x.lastName,
                  memberID: x.memberID,
                  state: x.primaryRegion,
                  providerID: x.memberProviders[0]?.providerID,
                  memberUniqueID: x.memberUniqueID,
                  email: x.email,
                  phonePrimary: x.phonePrimary,
                  memberType: x.memberType,
                  timeZone: x.timeZone,
                  parentID: x.parentID,
                  memberEmergencyContact: x.memberEmergencyContact,
                  dateOfBirth: x.dateOfBirth
                });
              });
            }
            //after we built the array if there is only one in it we dont need to return it.
            return returnArray.length == 1 ? [] : returnArray;
          }
        }
      };
    },
    getFamilyMembersForVisit: (state) => {
      return (memberID) => {
        //check to see if there are any dependents, if not we can shortcut.
        if (
          state.userDetails.dependents &&
          state.userDetails.dependents.length > 0
        ) {
          //check to find who the current member is.
          let currentMember;
          if (state.userDetails.member.memberID == memberID) {
            currentMember = state.userDetails.member;
          } else {
            currentMember = state.userDetails.dependents.find(
              (x) => x.memberID == memberID
            );
          }
          //spouse and primary can see themselves plus any children.

          if (
            currentMember.memberType == lookupTypes.dependentType.spouse ||
            currentMember.memberType == lookupTypes.memberType.primary || currentMember.memberType == lookupTypes.dependentType.adultChild
          ) {
            let returnArray = [];

            returnArray.push({
              name: currentMember.firstName + " " + currentMember.lastName,
              memberID: memberID,
              state: currentMember.primaryRegion,
              providerID: currentMember.memberProviders[0]?.providerID,
              memberUniqueID: currentMember.memberUniqueID,
              email: currentMember.email,
              phonePrimary: currentMember.phonePrimary,
              memberType: currentMember.memberType,
              timeZone: currentMember.timeZone,
              parentID: currentMember.parentID,
              memberEmergencyContact: currentMember.memberEmergencyContact,
              dateOfBirth: currentMember.dateOfBirth
            });

            //now get the children if there are any.
            let filteredDep = state.userDetails.dependents.filter(
              (x) => x.memberID != currentMember.memberID
            );
            if (filteredDep && filteredDep.length) {
              filteredDep.forEach((x) => {
                returnArray.push({
                  name: x.firstName + " " + x.lastName,
                  memberID: x.memberID,
                  state: x.primaryRegion,
                  providerID: x.memberProviders[0]?.providerID,
                  memberUniqueID: x.memberUniqueID,
                  email: x.email,
                  phonePrimary: x.phonePrimary,
                  memberType: x.memberType,
                  timeZone: x.timeZone,
                  parentID: x.parentID,
                  memberEmergencyContact: x.memberEmergencyContact,
                  dateOfBirth: x.dateOfBirth
                });
              });
            }
            //after we built the array if there is only one in it we dont need to return it.
            return returnArray.length == 1 ? [] : returnArray;
          }
        }
      };
    },
    // get full member list, along with the login user details
    getFullFamilyMembers: (state) => {
      return (memberID) => {

        let returnArray = [];          
        let currentMember;
        if (state.userDetails.member.memberID == memberID) {
          currentMember = state.userDetails.member;
        }
        if(!currentMember){
          currentMember = state.userDetails.dependents.find(
            (x) => x.memberID == memberID
          );
        }

        //add the current member
        returnArray.push({
          name: currentMember.firstName + " " + currentMember.lastName,
          memberID: memberID,
          state: currentMember.primaryRegion,
          providerID: currentMember.memberProviders[0]?.providerID,
          memberUniqueID: currentMember.memberUniqueID,
          email: currentMember.email,
          phonePrimary: currentMember.phonePrimary,
          memberType: currentMember.memberType,
          timeZone: currentMember.timeZone,
        });

        //check child members
        if (
          currentMember.memberType == lookupTypes.dependentType.spouse ||
          currentMember.memberType == lookupTypes.memberType.primary
        ) {
          //now get the children if there are any.
          let filteredDep = state.userDetails.dependents.filter(
            (x) => x.memberType == lookupTypes.dependentType.child
          );
          if (filteredDep && filteredDep.length) {
            filteredDep.forEach((x) => {
              returnArray.push({
                name: x.firstName + " " + x.lastName,
                memberID: x.memberID,
                state: x.primaryRegion,
                providerID: x.memberProviders[0]?.providerID,
                memberUniqueID: x.memberUniqueID,
                email: x.email,
                phonePrimary: x.phonePrimary,
                memberType: x.memberType,
                timeZone: x.timeZone,
              });
            });
          }
        }

        return returnArray;
      };
    },   
    getVisitSchedulers: (state) => {
      return () => {

        let dependents = state.userDetails.dependents;

        dependents.push(state.userDetails.member);

        let visitSchedulers = dependents.filter(
          (x) => x.memberType == lookupTypes.dependentType.spouse || x.memberType == lookupTypes.memberType.primary
        );

        return visitSchedulers;
      }
    },
    getVisitSchedulersForSwift: (state) => {
      return (memberID, selectedMemberID) => {

        if(memberID == selectedMemberID) return [];

        let schedulers = [];

        let currentMember;
        if (state.userDetails.member.memberID == memberID) {
          currentMember = state.userDetails.member;
        } else {
          currentMember = state.userDetails.dependents.find(
            (x) => x.memberID == memberID
          );
        }
        schedulers.push(currentMember);

        let selectedMember;
        if (state.userDetails.member.memberID == selectedMemberID) {
          selectedMember = state.userDetails.member;
        } else {
          selectedMember = state.userDetails.dependents.find(
            (x) => x.memberID == selectedMemberID
          );
        }

        let dependents = state.userDetails.dependents;
        dependents.push(state.userDetails.member);

        if(selectedMember.memberType == lookupTypes.dependentType.child  && 
            (currentMember.memberType == lookupTypes.memberType.primary || currentMember.memberType == lookupTypes.memberType.spouse)){
              let visitSchedulers = dependents.filter(
                (x) => (x.memberType == lookupTypes.dependentType.spouse || x.memberType == lookupTypes.memberType.primary && x.memberID != memberID)
              );
    
              schedulers.push(...visitSchedulers);
              return schedulers;
        }

        if(selectedMember?.email && !(selectedMember.memberType == lookupTypes.dependentType.child && currentMember.memberType == lookupTypes.dependentType.adultChild))
          schedulers.push(selectedMember);

        if(selectedMember.memberType == lookupTypes.dependentType.child && currentMember.memberType == lookupTypes.dependentType.adultChild){
          let visitSchedulers = dependents.filter(
            (x) => (x.memberType == lookupTypes.dependentType.spouse || x.memberType == lookupTypes.memberType.primary)
          );

          schedulers.push(...visitSchedulers);
        }
      
        return schedulers;
      }
    },
    getIsUsingHighDeductableFormulary: (state) => {
      return () => {
        const highDeductableFormularyEmployerIds = process.env.VUE_APP_HDHP_GROUPS.split(",");
        return highDeductableFormularyEmployerIds.includes(state.userDetails.member.employerID);
      }
    },

    async getAddressFromZipcode(zipcode) {
      const apiKey = process.env.VUE_APP_GOOGLE_GEOLOCATION_KEY;

      axios
        .get(
          `${process.env.VUE_APP_GOOGLE_GEOLOCATION_API}?address=${zipcode}&key=${apiKey}`
        )
        .then(
          (response) => {
            const addressComponents =
              response.data.results[0].address_components;

            const state = addressComponents.find((component) =>
              component.types.includes("administrative_area_level_1")
            ).short_name;

            const city = addressComponents.find((component) =>
              component.types.includes("locality")
            ).long_name;

            const address = city + ", " + state;

            this.geoAddress = address;
          },
          (error) => {
            console.log("error", error);
          }
        );
    },

    //check if the product requires user to select a member
    checkIfProductRequiresMember: () => {
      return (productAttributes) => {
        let found = productAttributes.find(
          (x) => x.attributeType == lookupTypes.productAttributes.memberSelect
        );
        return found && found.attributeValue === "true";
      };
    },
    
  },
  persist: true,
  actions: {
    async getProviderAvailabilityByDate(payload, idToken) {
      try {
        let response = await axios.get(
          `${process.env.VUE_APP_GET_PROVIDER_AVAILABILITY_BY_DATE}?providerID=${payload.providerID}&from=${payload.from}&to=${payload.to}&state=${payload.state}&memberID=${payload.memberID}`,
          {
            headers: { Authorization: `Bearer ${idToken}` },
          }
        );
        return response.data;
      } catch (error) {
        return error;
      }
    },
    // FIXME: much of this code is identical to `getProfileMemberDetails` in `DashBoard.vue`
    //   and `fetchProfileMemberDetails` in `HomePageV2.vue`.
    //   Keep this one.
    async setupMemberDetails(authEmail, idToken) {
      const response = await fetchProfileMemberDetails(authEmail, idToken);
      const { member, signupPageProductDetails, dependents } =
        response.data?.data || {};
      
      this.userDetails.member = member;
      if (member && member.overrideFamilyEdit) {       
        this.employerProfileAttribute.dependentAddOrEdit = 2;
      }

      if (this.getIsUsingHighDeductableFormulary()) {
        this.formularyUser = "HighDeduct";
      } else if (signupPageProductDetails && signupPageProductDetails.length) {
        const checkUserGNC = signupPageProductDetails[0].products.some(
          (item) => item.productID == process.env.VUE_APP_GNC_PRODUCT_ID
        );
        this.formularyUser = checkUserGNC ? "GNC" : "Default";

        const findRetail = signupPageProductDetails[0].products.some(
          (item) => item.name == "Retail Pharmacy"
        );
        this.productName = findRetail ? "Retail Pharmacy" : "Prescriptions";
      } else {
        this.formularyUser = "Default";
        this.productName = "Prescriptions";
      }

      const defaultMemberProviders = [
        {
          memberProvidersRowID: "",
          memberID: "",
          providerID: "",
          entityID: "",
        },
      ];
      if (!member.memberProviders || !member.memberProviders.length) {
        this.userDetails.member.memberProviders =  defaultMemberProviders;
      }

      this.memberUniqueID = member?.memberUniqueID.replace("-","");
      this.userDetails.dependents = dependents || [];

      const primaryMember = 
        this.userDetails.member.memberType == lookupTypes.memberType.primary
          ? this.userDetails.member
          : this.userDetails.dependents.find(
              (x) => x.memberType == lookupTypes.memberType.primary
            );

      if (!this.userDetails.member.timeZone)
        this.userDetails.member.timeZone = primaryMember.timeZone;

      this.userDetails.dependents.forEach((dep) => {
        if (!dep.memberProviders || !dep.memberProviders.length) {
          dep.memberProviders = defaultMemberProviders;
        }
        if (!dep.timeZone) dep.timeZone = primaryMember.timeZone;
      });
    },
  },
});
